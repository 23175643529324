@import 'styles/constants';

.table-wrapper {
	width: 100%;
	overflow: auto;
	margin: indent(3) 0;
}

.table {
	width: 100%;

	tr p {
		margin: 0;
	}

	th {
		border-width: 1px;
		padding: indent(1);
	}

	td {
		border-width: 1px;
		padding: indent(0.5);
		vertical-align: top;
	}
}
