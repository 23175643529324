@import 'styles/constants';

.banner {
	margin: 0 -#{$container-padding-x};
	text-align: center;

	&__picture {
		display: inline-block;
		img {
			width: 100%;
		}
	}

	@include md-up {
		margin: 0;
		&__picture {
			display: block;
		}
	}

	@include xl-up {
		text-align: right;
		&__picture {
			display: inline-block;
		}
	}
}

.list {
	@include reset-list;
	margin-top: indent(5);

	&__item {
		padding: indent(3) 0 indent(3);
		border-top: 2px solid #eaeaff;

		&:last-child {
			border-bottom: 2px solid #eaeaff;
		}
	}

	&__label {
		margin-bottom: indent(2);
		line-height: 1.25;
	}

	@include md-up {
		margin-top: indent(4);
	}

	@include lg-up {
		margin-top: 0;
	}
}
