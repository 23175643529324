@import 'styles/constants';

.wrapper {
	box-shadow: $card-shadow;
	border-radius: 10px;
	overflow: hidden;
	min-height: 536px;
	display: flex;
	flex-direction: column;

	@include md-up {
		flex-direction: row;
		border-radius: 10px 0 0 10px;
		min-height: 240px;
	}
	@include lg-up {
		min-height: 200px;
	}
	@include xl-up {
		min-height: 264px;
	}
}

.img-link {
	position: relative;
	display: block;
	width: 100%;
	height: 100%;

	min-height: 264px;
	
	@include md-up {
		min-width: 229px;
		min-height: unset;
	}

	@include lg-up {
		min-width: 191px;
	}

	@include xl-up {
		min-width: 252px;
	}
}

.img {
	object-fit: cover;
}

.content {
	padding: indent(2);
	display: flex;
	flex-direction: column;
	flex: 1;
	@include md-up {
		padding: indent(3);
	}
}

.title {
	margin-bottom: indent(1);
	color: color(blue700);

	a {
		color: color(blue700);
	}

	@include md-up {
		margin-bottom: indent(2);
	}
}

.description {
	height: 144px;
	width: 100%;
	display: -webkit-box;
	-webkit-line-clamp: 6;
	-webkit-box-orient: vertical;
	overflow: hidden;

	@include md-up {
		height: 96px;
		-webkit-line-clamp: 4;
	}

	@include lg-up {
		height: 78px;
		-webkit-line-clamp: 3;
	}

	@include xl-up {
		height: 120px;
		-webkit-line-clamp: 4;
	}
}

.footer {
	margin-top: auto;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: indent(1) 0;

	@include md-up {
		padding-bottom: 0;
	}
}

.date {
	text-transform: uppercase;
	@include font-size(fs(xxs));
}

.read {
	text-transform: uppercase;
	color: color(blue800);
}

.isVertical {
	&.wrapper {
		flex-direction: column;
		min-height: 264px;
		border-radius: 10px;

		.figure {
			position: relative;
			min-height: 264px;
		}

		.title {
			@include lg-up {
				min-height: 48px;
			}
		}

		.description {
			@include xl-up {
				height: 149px;
				-webkit-line-clamp: 6;
			}
			@include lg-up {
				height: 149px;
				-webkit-line-clamp: 6;
			}
		}

		.footer {
			padding: indent(2) 0 indent(1);
		}
	}
}
