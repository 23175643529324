@import 'styles/constants';

.top {
	background: url('/images/backgrounds/home-top-375px.svg') center no-repeat, $gradient-header;
	padding: indent(2) 0 285px;

	&__navigation {
		background: transparent;
	}

	&__title {
		color: color(white);
		font-weight: 400;
		text-align: center;

		font-size: fs(3);
		line-height: 1.5;
		@include md-up {
			margin-top: indent(2);
		}
	}

	&__subtitle {
		color: color(white);
		font-weight: 400;
		text-align: center;
		margin-top: indent(3);
	}

	@include xxs-up {
		padding: indent(4) 0 276px;
		&__title {
			font-size: fs(2);
			line-height: 1.25;
		}
	}

	@include sm-up {
		padding: indent(5) 0 356px;
		&__title {
			font-size: fs(1);
			margin-top: indent(6.5);
		}
	}

	@include md-up {
		background: url('/images/backgrounds/home-top-768px.svg') center no-repeat, $gradient-header;
		padding-bottom: 316px;
	}

	@include lg-up {
		background: url('/images/backgrounds/home-top-1024px.svg') center no-repeat, $gradient-header;
	}

	@include xl-up {
		background: url('/images/backgrounds/home-top-1440px.svg') center no-repeat, $gradient-header;
	}
}

.tools-list {
	margin-top: -356px;

	&__component {
		margin-top: indent(13.5);
	}

	li:first-of-type {
		color: color(white);
	}

	@include md-up {
		margin-top: indent(-39.5);
		&__component {
			margin-top: indent(6.5);
		}
		li {
			color: color(white);
		}
	}
	@include md-up {
		&__component {
			margin-top: indent(15.5);
		}
	}
}

.section {
	margin-bottom: indent(5);
	&__title-block {
		margin-bottom: indent(4);
	}

	&__title {
		margin-bottom: indent(2);
		text-align: center;
		font-weight: 400;
	}

	&__subtitle {
		text-align: center;
	}

	@include md-up {
		margin-bottom: indent(9);
	}

	@include sm-up {
		&__title-block {
			margin-bottom: indent(6);
		}
	}
}

.benefits {
	padding: indent(5) 0;
	margin-bottom: 0;

	&__title {
		text-align: center;
	}

	@include md-up {
		padding: indent(9) 0 indent(6);
	}
}

.how {
	margin-bottom: indent(5);
	@include md-up {
		margin-top: indent(-7);
		padding-top: indent(7);
		background: url('/images/backgrounds/line-bg.svg') no-repeat;
		background-position-x: calc(50% + 120px);
	}

	@include lg-up {
		margin-bottom: indent(9);
		background-position-x: calc(50% + 220px);
	}

	&__what-row {
		@include md-up {
			margin-top: indent(4);
			margin-bottom: indent(4);
		}
	}

	&__list {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		margin-right: -$grid-gutter-width / 2;
		margin-left: -$grid-gutter-width / 2;
	}

	&__item {
		margin-left: $grid-gutter-width / 2;
		margin-right: $grid-gutter-width / 2;
		margin-bottom: indent(4);
		min-width: 300px;
		max-width: 348px;
	}

	&__item:last-child {
		margin-bottom: 0;
		@include xl-up {
			margin-bottom: indent(4);
		}
	}
}

.faq {
	margin-bottom: indent(10);

	@include lg-up {
		margin-bottom: indent(8);
	}

	@include xl-up {
		margin-top: indent(7);
		margin-bottom: indent(11.5);
	}
}

.make-document {
	margin: indent(10) 0 indent(13);

	@include md-up {
		margin-bottom: indent(10);
	}

	@include lg-up {
		margin-top: indent(8);
	}

	@include xl-up {
		margin: indent(11.5) 0 indent(15);
	}
}
