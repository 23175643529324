@import 'styles/constants';

.wrapper {
	position: relative;
	flex: 1;
}

.content {
	margin-top: indent(1);
	margin-bottom: -182px;

	display: flex;
	flex-direction: column;
	align-items: center;
}

.title {
	margin-bottom: indent(2);
}

.card {
	width: 163px;
	height: 208px;
	box-shadow: 0px 2px 17px rgba(43, 48, 153, 0.2);
	background: color(white);
	border-radius: 7.30942px;
	margin-bottom: indent(2);
	padding: indent(1.5);
	padding-bottom: indent(2);

	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	align-items: center;

	position: relative;

	&__icon {
		position: absolute;
		top: indent(1.5);
		&--link {
			cursor: pointer;
		}
	}

	&__preview-btn {
		color: color(blue700);
	}

	img {
		height: auto;
		width: 100%;
	}
}

.icon {
	&__wrapper {
		padding: 0 12px;
	}
}

.bottom {
	height: 208px;
	box-shadow: $card-shadow;
	border-radius: 10px;
}

.paystub {
	.bottom {
		background: color(lightBlue);
	}
}

.form1099 {
	.bottom {
		background: color(lightPurple);
	}
}

.w2 {
	.bottom {
		background: color(lightPeach);
	}
}
