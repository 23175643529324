@import 'src/styles/constants';

.wrapper {
	--navigation-size: 40px;

	min-height: 300px;
	height: 100%;
	:global(.swiper) {
		width: 100%;
		height: 100%;
		padding-bottom: calc(var(--navigation-size) + 20px);
	}

	@mixin separator {
		&::before {
			position: absolute;
			right: 0;
			content: '';
			display: block;
			padding-top: 70%;
			opacity: 0.35;
			border-left: 1px solid color(blue700);
			transform: translateX(-50%);
		}
	}

	:global(.swiper-slide-active) {
		@include md-up {
			@include separator;
		}
	}

	:global(.swiper-slide-next) {
		@include xxl-up {
			@include separator;
		}
	}

	:global(.swiper-slide) {
		text-align: center;
		font-size: 18px;
		/* Center slide text vertically */
		display: flex;
		justify-content: center;
		align-items: center;
	}

	:global(.swiper-slide img) {
		display: block;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	:global(.swiper-button-prev),
	:global(.swiper-button-next) {
		width: var(--navigation-size);
		height: var(--navigation-size);
		background: #000;

		position: absolute;
		z-index: 10;
		bottom: 0;
		transition: all 0.3s ease-in-out;
	}

	:global(.swiper-button-disabled) {
		opacity: 0.5;
	}
	:global(.swiper-button-prev) {
		right: calc(50% + 50px);
		background: url('/icons/arrow-right.svg') no-repeat center center;
		transform: rotate(180deg);
	}
	:global(.swiper-button-next) {
		background: url('/icons/arrow-right.svg') no-repeat center center;
		left: calc(50% + 50px);
	}
}

.content {
	width: 300px;

	&__title {
		font-size: fs(3);
		font-weight: 700;
		margin-top: indent(3);
		margin-bottom: indent(1.5);
		text-transform: uppercase;
	}

	&__author {
		font-size: fs(m);
		font-weight: 700;
		margin-bottom: indent(1.5);
		text-transform: uppercase;
		margin-top: indent(2);
	}

	@include lg-up {
		width: 347px;
	}
}
