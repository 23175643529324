@import 'styles/constants';

.wrapper {
	max-width: 342px + $grid-gutter-width;
	margin: 0 auto;

	@include md-up {
		max-width: unset;
	}

	@include lg-up {
		margin-top: indent(7);
	}
}

.list {
	@include reset-list;
	display: flex;
	flex-direction: column;
	align-items: center;
	flex-wrap: wrap;

	@include xxs-up {
		flex-direction: row;
		justify-content: center;
	}

	&__item {
		min-width: 215px;
		margin: 0 0 10px 0;
	}

	&__btn {
		width: 100%;
	}

	@include md-up {
		justify-content: space-between;

		&__item:nth-child(n) {
			margin: 0;
			flex: 0;
		}
	}
}
