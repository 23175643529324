@import 'styles/constants';

.wrapper {
	background: color(white);
	border: 1px solid #e9e9ff;
	box-shadow: 0px 8px 40px rgba(0, 17, 106, 0.08);
	border-radius: 10px;
	padding: 38px;

	@include md-up {
		padding: 40px;
	}

}

.step {
	font-weight: 700;
	font-size: 24px;
	line-height: 32px;
	text-transform: uppercase;
  margin-bottom: indent(1.5);

	color: color(orange500);
}

.title{
  font-weight: 700;
  font-size: fs(m);
  text-transform: uppercase;
  margin-bottom: indent(1);

  color: color(blue800);
}
