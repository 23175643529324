@import 'styles/constants';

.list {
	list-style: none;
	padding: 0;

	&__item {
		margin-bottom: indent(4);

		@include md-up {
			margin-bottom: 0;
		}
	}
}
