@import 'src/styles/constants';

.wrapper {
	@include reset-list;

  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 -18px;
  
}

.item {
	text-align: center;
  margin-right: 18px;
  margin-left: 18px;
	margin-bottom: indent(4);
  max-width: 281px;

	&:last-child {
		margin-bottom: 0;
	}

	&__icon {
		display: inline-flex;
		align-items: center;
		justify-content: center;
		width: 88px;
		height: 88px;
		margin-bottom: indent(3);
	}

	&__label {
		font-size: font-size(2);
		font-weight: font-weight(bold);
		margin-bottom: indent(1);
	}
}
