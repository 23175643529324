@import 'styles/constants';

.wrapper {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-bottom: indent(2);

		a {
			color: color(orange500);
		}

		&:not(:first-child) {
			margin-top: indent(3);
		}
	}

	p {
		margin-bottom: indent(2);
	}
}

.detailed-list {
	padding-left: indent(5);
	margin-bottom: indent(3);

	&__description {
		margin-left: indent(-2);
	}
}

.orange {
	color: color(orange500);
}

.block-image {
	margin-top: indent(2);
	margin-bottom: indent(2);
}

.img {
	height: auto;
}
