@import 'styles/constants';

.list {
	@include reset-list;

	&__item:not(:last-child) {
		margin-bottom: indent(4);
	}

	@include md-up {
		&__item:not(:last-child) {
			margin-bottom: indent(3);
		}
	}
	@include lg-up {
		margin-top: -3px;
	}
}

.benefit {
	text-align: center;

	&__icon {
		width: 88px;
		min-width: 88px;
		height: 88px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		margin-bottom: indent(2);

		box-shadow: $card-shadow;
		border-radius: 10px;
	}

	&__label {
		margin-bottom: indent(1);
	}

	&__description {
		letter-spacing: 0.1px;
	}

	@include md-up {
		display: flex;
		text-align: left;

		&__icon {
			margin: 0;
			margin-right: indent(3);
		}
	}
}
