$variable-prefix: '';

$colors: (
	blue800: #09205d,
	blue700: #0e308b,
	blue400: #4673ec,
	blue100: #e9e9ff,
	yellow400: #f2c027,
	orange500: #f89220,
	orange200: #faf6e8,
	orange100: #ffecda,
	purple100: #f8e9ff,
	red400: #de5454,
	red100: #fbe9e9,
	green500: #57dc69,
	green400: #38b449,
	green100: #e9fae8,
	gray: #e6e7ec,
	brainable-grey-dark: #818181,
	gray500: #8c93a6,
	gray600: #9b9b9b,
	white: #fff,
	black: #000,
	lightBlue: #e9e9ff,
	lightPeach: #ffecda,
	lightPurple: #f8e9ff,
);

$grays: ();
$theme-colors: ();

$gradient-header: radial-gradient(
		60.83% 440.72% at 89.1% 81.68%,
		#4e74f4 0%,
		rgba(60, 99, 234, 0) 100%
	),
	radial-gradient(74.3% 164.26% at 5.59% 25.7%, #3c63ea 0%, rgba(60, 99, 234, 0) 100%), #3635eb;

$card-shadow: 0px 8px 40px rgba(0, 17, 106, 0.08);
$button-shadow: 0px 6px 10px rgba(0, 17, 106, 0.1);

$spacer: 8px;
$spacers: (
	0: 0,
	1: $spacer,
	2: $spacer * 2,
	3: $spacer * 3,
	4: $spacer * 4,
	5: $spacer * 5,
);

$grid-columns: 12 !default;
$grid-gutter-width: 32px;
$grid-row-columns: 6 !default;

$gutters: $spacers !default;

$container-padding-x: $grid-gutter-width * 0.5 !default;

$container-max-widths: (
	sm: 540px + $grid-gutter-width,
	md: 688px + $grid-gutter-width,
	lg: 880px + $grid-gutter-width,
	xl: 1108px + $grid-gutter-width,
	xxl: 1288px + $grid-gutter-width,
);

$grid-breakpoints: (
	xs: 0,
	xxs: 375px,
	sm: 576px,
	md: 768px,
	lg: 1024px,
	xl: 1440px,
	xxl: 1920px,
);

// Typography
//
$enable-rfs: false;
$body-color: map-get($colors, blue800);
$font-family: var(--font-lato), 'Lato', sans-serif;
$font-family-sans-serif: $font-family;
$font-family-monospace: $font-family;

$font-family-base: var(--font-lato), 'Lato', sans-serif !default;

// $font-size-root affects the value of `rem`, which is used for as well font sizes, paddings, and margins
// $font-size-base affects the font size of the body text
$font-size-base: 1rem;
$font-size-sm: $font-size-base * 0.875 !default;
$font-size-lg: $font-size-base * 1.25 !default;

$font-weight-lighter: lighter !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 700 !default;
$font-weight-bolder: bolder !default;

$font-weight-base: $font-weight-normal !default;

$line-height-base: 1.5 !default;
$line-height-sm: 1.33;
$line-height-lg: 2 !default;

$h1-font-size: $font-size-base * 2;
$h2-font-size: $font-size-base * 1.5;
$h3-font-size: $font-size-base * 1;
$h4-font-size: $font-size-base * 0.9;
$h5-font-size: $font-size-base * 0.85;
$h6-font-size: $font-size-base * 0.75;
// scss-docs-end font-variables

// scss-docs-start font-sizes
$font-sizes: (
	1: $h1-font-size,
	2: $h2-font-size,
	3: $h3-font-size,
	4: $h4-font-size,
	5: $h5-font-size,
	6: $h6-font-size,
	m: $font-size-base,
	tab: $font-size-base,
	s: $font-size-base * 0.875,
	xs: $font-size-base * 0.75,
	xxs: $font-size-base * 0.625,
);
// scss-docs-end font-sizes

// scss-docs-start headings-variables
$headings-margin-bottom: $spacer * 0.5 !default;
$headings-font-family: null !default;
$headings-font-style: null !default;
$headings-font-weight: 700;
$headings-line-height: 1.25;
$headings-color: null !default;
// scss-docs-end headings-variables

$input-radius: 2px;

// extra
$transition300ease: all 0.3s ease;
