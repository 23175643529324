@import 'src/styles/constants';

.wrapper {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;

	margin: 0 #{-$container-padding-x};
}

.post {
	width: 100%;
	margin: $container-padding-x;

	@include md-up {
		max-width: 520px;
	}
	@include lg-up {
		max-width: 348px;
	}
}
