.header {
  border: none;
  background: none;
  outline: none;
  padding: 0;
  color: inherit;
}
.header:not(.disabled) {
  cursor: pointer;
}
