@import 'styles/constants';

.item-body {
	margin-top: 8px;
	display: inline-block;

  p:last-child {
    margin-bottom: 0;
  }
}
.header {
	display: flex;
	text-align: left;
	font-weight: bold;
	width: 100%;
	justify-content: space-between;
	letter-spacing: normal;
	text-transform: none;

	&__icon {
		margin-right: 16px;
		flex: 0 0 auto;
		padding-top: 7px;
		display: flex;
	}

	&__text {
	display: block;
	padding-right: 5px;
	}
}

.accordion-header {
	width: 100%;
}
